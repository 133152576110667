








import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class Alert extends Vue {
  @Prop(Object) readonly errors!: object;
}
